<template>
  <div>
    <div class="pa-4 white radius-15">
      <personal-infos :info="profileNew" :completeProfile="true" @nextHandler="$emit('nextHandler', 2)"></personal-infos>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PersonalInfos from '../../../dashboard/components/dialogs/PersonalInfos.vue'
export default {
  components: { PersonalInfos },
  computed:{
    ...mapGetters(['profileNew'])
  },
  methods:{
  }
}
</script>

<style>

</style>