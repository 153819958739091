<template>
  <div class="personal-info relative d-block">
    <v-form v-model="valid" @submit.prevent="updateHandler" ref="personalInfo">
      <div
        :class="
          isMd ? ' text-center ' : 'd-flex align-center justify-space-between'
        "
      >
        <span
          class="d-block font-700 darkbord--text"
          :class="isMobile ? 'font-18 mb-2' : 'font-24 '"
          >{{ $t("forms.editPersonalInformation") }}</span
        >
        <dialog-btns
          v-if="isMobile"
          :topBtns="true"
          :btnTitle="$t('forms.save')"
          closeTitle="Discard"
          @click="resetHandler"
          @close="$emit('close')"
        ></dialog-btns>
      </div>
      <v-row class="mt-5">
        <!-- first name -->
        <v-col cols="12" md="6">
          <base-input
            v-model="obj.first_name"
            :isRequired="true"
            :placeholder="$t('forms.firstName')"
            :title="$t('forms.firstName')"
          ></base-input>
        </v-col>

        <!-- last name -->
        <v-col cols="12" md="6">
          <base-input
            v-model="obj.last_name"
            :isRequired="true"
            :placeholder="$t('forms.lastName')"
            :title="$t('forms.lastName')"
          ></base-input>
        </v-col>

        <!-- email -->
        <v-col cols="12">
          <base-input
            v-model="obj.email"
            :isRequired="true"
            :placeholder="$t('forms.email')"
            :title="$t('forms.email')"
          ></base-input>
        </v-col>
        <!-- Country -->
        <!-- <v-col cols="12">
          <base-select
            v-model="obj.country_id"
            :items="dataLists.countries"
            itemText="name"
            itemValue="id"
            :placeholder="$t('forms.country')"
            :title="$t('forms.country')"
            class="mt-1"
            :noTitle="true"
          ></base-select>
        </v-col> -->

        <!-- phone number -->
        <v-col cols="12" class="mt-0">
          <v-row class="px-0 mt-0">
            <v-col cols="12" md="6" class="mt-0 pt-0">
              <base-auto-complete
                :isPhone="true"
                placeholder="Lebanon(+961)"
                :items="filteredCountries"
                itemValue="id"
                itemText="name"
                :title="$t('forms.countryCode')"
                v-model="obj.country_code"
                :noTitle="false"
                isCountrySelect
              ></base-auto-complete>
            </v-col>
            <v-col cols="12" md="6" class="mt-0 pt-0">
              <base-input
                v-model="obj.phone"
                placeholder="9999999"
                :title="$t('forms.phone')"
                :noTitle="false"
              ></base-input>
            </v-col>
          </v-row>
        </v-col>

        <!-- Specializations -->
        
        <v-col cols="12" class="mt-2">
          <base-auto-complete
          v-model="obj.specializations"
          :isRequired="true"
          :items="dataLists.specializations"
          :placeholder="$t('forms.specializations')"
          :title="$t('forms.specializations')"
          :multiple="true"
          @removeHandler="removeHandler"
        ></base-auto-complete>
        </v-col>
        <!-- Nationality * -->

        <v-col cols="12" md="6" class="mt-2">
          <base-auto-complete
            v-model="obj.nationality_id"
            :items="dataLists.nationalities"
            :isRequired="true"
            itemValue="id"
            :placeholder="$t('forms.nationality')"
            :title="$t('forms.nationality')"
          ></base-auto-complete>
        </v-col>
        <!-- Other Nationality-->
        <v-col cols="12" md="6" class="mt-2">
          <base-auto-complete
            v-model="obj.other_nationality_id"
            :items="dataLists.nationalities"
            :isRequired="false"
            itemValue="id"
            :placeholder="$t('forms.otherNationality')"
            :title="$t('forms.otherNationality')"
            :rules="[]"
          ></base-auto-complete>
        </v-col>

        <v-col cols="12" class="dashbase-input dashbase-auto">
          <base-auto-complete
            class="mt-4"
            :isRequired="false"
            :noTitle="true"
            v-model="obj.experience_id"
            :items="dataLists.experiences"
            :placeholder="$t('forms.yearsOfExperience')"
            :title="$t('forms.yearsOfExperience')"
            @input="experienceHandler($event, 'experience')"
          ></base-auto-complete>
        </v-col>

        <!-- Healthcare provider type -->
        <v-col cols="12">
          <base-auto-complete
            v-model="obj.healthcare_provider_type_id"
            :items="dataLists.health_care"
            :isRequired="true"
            :placeholder="$t('forms.healthcareProviderType')"
            :title="$t('forms.healthcareProviderType')"
            @input="experienceHandler"
          ></base-auto-complete>
        </v-col>
      </v-row>
      <dialog-btns
        v-if="!isMobile"
        :btnTitle="
          completeProfile ? $t('forms.saveContinue') : $t('forms.save')
        "
        :closeTitle="completeProfile ? '' : $t('forms.discard')"
        @close="$emit('close')"
      ></dialog-btns>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseInput from "../BaseInput.vue";
import BaseAutoComplete from "../BaseAutoComplete.vue";
import DialogBtns from "./DialogBtns.vue";
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    completeProfile: {
      type: Boolean,
      default: false,
    },
  },
  components: { DialogBtns, BaseInput, BaseAutoComplete },
  data: () => ({
    dataLists: {},
    obj: { country_code: "", country: [] },
    valid: false,
    selectedAll: false,
  }),
  computed: {
    ...mapGetters(["userType", "countries"]),
    filteredCountries() {
      return this.countries.countries.map((item) => {
        return {
          ...item,
          name: item.name + " " + ` (+${item.phone_code}) `,
        };
      });
    },
  },
  methods: {
    removeHandler(item) {
      if (this.obj.specializations[0] && this.obj.specializations[0].id) {
        this.obj.specializations = this.obj.specializations.map((i) => i.id);
      }
      const index = this.obj.specializations.indexOf(item.id);
      if (index >= 0) this.obj.specializations.splice(index, 1);
    },
    experienceHandler(event, type) {
      if (type == "experience") {
        if (event == 5) {
          this.obj.healthcare_provider_type_id = 1;
        }
      } else {
        if (event == 1) {
          this.obj.experience_id = 5;
        }
      }
    },
    async getDataLists() {
      let { data } = await this.$axios("information/data");
      if (data) {
        this.dataLists = data.data;
      }
    },
    async updateHandler() {
      let valid = this.$refs.personalInfo.validate();
      if (!valid) {
        this.$store.dispatch("showSnack", {
          text: this.$t("rules.checkAllFields"),
          color: "error",
        });
        return;
      }
      let url;
      if (this.userType == "doctor") {
        url = "update/personal/information";
      }
      let formData = new FormData();

      formData.append("first_name", this.obj.first_name);
      formData.append("last_name", this.obj.last_name);
      formData.append(`country`, this.obj.country_id);
      formData.append("email", this.obj.email);
      formData.append("country_code", this.obj.country_code);
      formData.append("phone", this.obj.phone);
      formData.append("nationality_id", this.obj.nationality_id);
      formData.append("other_nationality_id", this.obj.other_nationality_id);
      formData.append("experience_id", this.obj.experience_id);
      formData.append(
        "healthcare_provider_type_id",
        this.obj.healthcare_provider_type_id
      );

      this.obj.specializations.forEach((item, idx) => {
        if (item.id) {
          formData.append(`specializations[${idx}]`, item.id);
        } else {
          formData.append(`specializations[${idx}]`, item);
        }
      });

      let { data } = await this.$axios.post(url, formData, {
        headers: {
          Accept: "application/json",
        },
      });
      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Updated Successfully",
          color: "success",
        });
        if (this.completeProfile) {
          this.$emit("nextHandler");
        }
        this.$emit("getData");
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
  },
  async mounted() {
    await this.getDataLists();
    this.obj = { ...this.info };
    this.obj.country_code = +this.obj.country_code;
  },
};
</script>

<style lang="scss">
.v-menu__content {
  .v-ripple__container {
    display: none !important;
  }
}
.theme--light.v-list-item.v-list-item--highlighted:before {
  display: none;
}
</style>
